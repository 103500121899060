<template>
  <dialog-outline v-model="showDialog" max-width="80%">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- context -->
    <template v-slot:context>
      <!-- Report Header -->
      <reporting-card
        :report-module="moduleNames.Projects"
        @download-report="onReportDownload"
      ></reporting-card>

      <!-- Report Context -->
      <v-container>
        <!-- Project Details title -->
        <v-card-title class="justify-center">
          <v-icon :color="titleColor" left>{{ iconProject }}</v-icon>
          <span :class="titleTextClass()">Project Details</span></v-card-title
        >
        <!-- project details display -->
        <!-- :isReport="true"-->
        <form-project :project="project"></form-project>

        <!-- User Tasks -->
        <v-card-title class="justify-center">
          <v-icon :color="titleColor" left>{{ iconTask }}</v-icon>
          <span :class="titleTextClass()">User Tasks</span></v-card-title
        >

        <!-- list of user tasks & details -->
        <v-row>
          <!-- list of user tasks -->
          <v-col style="font-size: 16px;" cols="12" md="4">
            <v-card>
              <v-card-title>
                <span :class="titleTextClass()">{{ userTaskListTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-divider></v-divider>
              </v-card-text>
              <v-list rounded nav>
                <v-list-item-group v-model="selectedTaskIndex">
                  <v-list-item v-for="task in userTasks" :key="task.taskId">
                    <v-list-item-icon>
                      <task-icon :task="task"></task-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ task.name }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <!-- user task details -->
          <v-col cols="12" md="8">
            <v-card>
              <v-card-title v-if="selectedTask">
                <task-icon :task="selectedTask"></task-icon>
                <span>{{ selectedTask?.name }}</span>
              </v-card-title>
              <v-card-text v-if="selectedTask">
                <v-divider></v-divider>
                <!-- project-task -->
                <project-task
                  :project="project"
                  :task="selectedTask"
                  :is-report="true"
                ></project-task>
              </v-card-text>
              <v-card-text v-else>
                <span :class="titleTextClass()">No Task Selected</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// design
import { iconProjects, iconTask } from "@/design/icon/iconConst";
import { displayTypes, fontEmphasis, Text } from "@/design/text/Text";
import { Color, colorMD } from "@/design/colors/Color";

//model
import { moduleNames } from "@/model/solution/moduleModel";
import { projectHeaders } from "@/model/workflow/project/projectModel";
import { taskHeaders } from "@/model/workflow/task/taskModel";

// mixins
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";
import { recordBannerAble } from "@/mixins/shared/bannerAble/recordBannerAble";
import { reportableMixin } from "@/mixins/shared/report/reportableMixin";
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";

export default {
  name: "ProjectDetailedReportDialog",
  mixins: [
    baseComponentMixin,
    recordBannerAble,
    reportableMixin,
    menuItemSettingsMixin
  ],
  props: {
    project: undefined
  },
  components: {
    // FormTask: () => import("@/components/task/FormTask"),
    ProjectTask: () => import("@/components/project/ProjectTask.vue"),
    TaskIcon: () => import("@/components/task/TaskIcon.vue"),
    FormProject: () => import("@/components/project/FormProject.vue")
  },

  data() {
    return {
      reportModule: moduleNames.Projects,
      selectedTaskIndex: -1,
      iconTask: iconTask,
      iconProject: iconProjects,
      titleColor: colorMD.indigo
    };
  },

  computed: {
    /**
     * Table Headers for a project
     * @return {{visible: boolean, text: string, value: string}[]}
     */
    tableHeaders() {
      return projectHeaders;
    },

    /**
     * Table Headers for a task of a project
     * @return {{visible: boolean, text: string, value: string}[]}
     */
    projectTaskHeaders() {
      return taskHeaders;
    },

    /**
     * Compute if a detailed report
     * should have multiples tables if true
     * @return {boolean}
     */
    isDetailedReport() {
      return true;
    },
    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.iconReport;
    },

    /**
     * Get Move Page Dialog title
     * @return {string} Move Page Dialog title
     */
    dialogTitle() {
      return `Detailed ${this.getReportModuleTitle} View`;
    },

    /**
     * Get Current Date
     * @return {Date}
     */
    currentDate() {
      return new Date();
    },

    /**
     * Get Table Record List
     * @return {*[]}
     */
    items() {
      return [this.project] ?? [];
    },

    /**
     * project Id
     * @return {number} project id
     */
    projectId() {
      return this.project?.projectId ?? -1;
    },

    /**
     * Project Tasks
     * @return {*|*[]}
     */
    userTasks() {
      return this.project?.userTasks ?? [];
    },

    /**
     * user Task Count
     * @return {number}
     */
    userTaskCount() {
      return this.userTasks.length;
    },

    /**
     * Open Tree by default
     * @return {(number)[]}
     */
    open() {
      return [this.projectId];
    },

    /**
     * Get Report Module Title
     * @return {String|string}
     */
    getReportModuleTitle() {
      if (this.reportModule === moduleNames.Folder) return "Records";
      return this.reportModule;
    },

    /**
     * user Task List Title
     * @return {string}
     */
    userTaskListTitle() {
      return this.userTaskCount > 1
        ? `(${this.userTaskCount}) User Tasks`
        : `(${this.userTaskCount}) User Task`;
    },

    /**
     * selected Task
     * @return {T|undefined}
     */
    selectedTask() {
      return this.selectedTaskIndex >= 0 &&
        this.selectedTaskIndex < this.userTaskCount
        ? this.userTasks[this.selectedTaskIndex]
        : undefined;
    }
  },
  methods: {
    titleTextClass() {
      return new Text(
        new Color(
          this.menuItemColor.name,
          this.menuItemColor.variantType,
          this.menuItemColor.variantNumber
        ),
        displayTypes.title,
        fontEmphasis.bold
      ).getClassText();
    }
  },
  watch: {
    /**
     * watch show this dialog flag
     * @param {boolean} newValue
     */
    showDialog(newValue) {
      if (newValue) {
        /**
         * select the first user task when the dialog is shown
         */
        this.selectedTaskIndex = this.userTasks.length > 0 ? 0 : -1;
      }
    }
  }
};
</script>
